import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";
import Nav from "../components/Nav/Nav";
import * as ACFModules from "../modules/Modules";
import Fallback from "../modules/Fallback";

export default function BlogPost({ data: { wpProject, allWpProject } }) {
  const {
    pageModules: { modules },
    seo,
    uri,
  } = wpProject;
  const projects = allWpProject;
  projects.nodes.sort((a, b) => a.menuOrder - b.menuOrder);

  const currentIndex = projects.nodes.findIndex(
    (node) => node.id === wpProject.id
  );

  return (
    <>
      <Seo seo={seo} />
      <main className="single_project">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{wpProject.title} - Seesaw Studio</title>
        </Helmet>
        <Nav position="top" slug={uri} />
        {modules &&
          modules.map((module, id) => {
            if (Object.keys(module).length > 0) {
              const moduleName = module.fieldGroupName.replace(
                "Project_Pagemodules_Modules_",
                ""
              );
              const Component = ACFModules[moduleName] || Fallback;
              if (moduleName === "NextPage") {
                if (allWpProject.nodes.length !== currentIndex + 1) {
                  return (
                    <Component
                      key={id}
                      {...module}
                      nextProject={allWpProject.nodes[currentIndex + 1]}
                    />
                  );
                }

                return (
                  <Component
                    key={id}
                    {...module}
                    nextProject={allWpProject.nodes[0]}
                  />
                );
              }
              return <Component key={id} {...module} />;
            }
          })}
      </main>
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpProject(id: { eq: $id }) {
      id
      title
      uri
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }

      pageModules {
        modules {
          ... on WpProject_Pagemodules_Modules_Hero {
            fieldGroupName
            images {
              altText
              publicUrl
              caption
              sourceUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpProject_Pagemodules_Modules_Content {
            fieldGroupName
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTop
              paddingTopMobile
            }
            backgroundColour
            heading
            text1
            text2
          }
          ... on WpProject_Pagemodules_Modules_TextAndImage {
            fieldGroupName
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTop
              paddingTopMobile
            }
            backgroundColour
            layout
            heading
            text1
            text2
            image {
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
              altText
            }
          }
          ... on WpProject_Pagemodules_Modules_TextWithImageLayers {
            fieldGroupName
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTop
              paddingTopMobile
            }
            layout
            heading
            text1
            text2
            backgroundColour
            media {
              type
              video {
                mediaItemUrl
              }
              image {
                altText
                publicUrl
                localFile {
                  extension
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
          }
          ... on WpProject_Pagemodules_Modules_ProjectHero {
            fieldGroupName
            backgroundColour
            heading
            content
            content2
            image {
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                  )
                }
              }
              altText
            }
            accordionList {
              info
              label
            }
          }
          ... on WpProject_Pagemodules_Modules_TwoColumnImages {
            fieldGroupName
            padding {
              paddingBottomMobile
              paddingTopMobile
            }
            layout
            mobileLayout
            backgroundColour
            leftImage {
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
              altText
            }
            rightImage {
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
              altText
            }
            videoLeft
            videoRight
          }
          ... on WpProject_Pagemodules_Modules_TwoPortraitImages {
            fieldGroupName
            mobileType
            backgroundColour
            slides {
              rightVideo
              leftVideo
              leftImage {
                publicUrl
                localFile {
                  extension
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
                altText
              }
              rightImage {
                publicUrl
                localFile {
                  extension
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
                altText
              }
            }
          }
          ... on WpProject_Pagemodules_Modules_SingleMedia {
            fieldGroupName
            enablePadding
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTop
              paddingTopMobile
            }
            disableParallaxEffect
            insetAnimation
            mediaType
            backgroundColour
            images {
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                  )
                }
              }
              altText
            }
            video {
              mediaItemUrl
            }
            videoUrl
            link
          }
          ... on WpProject_Pagemodules_Modules_ScrollingScreens {
            fieldGroupName
            backgroundColour
            screens {
              image {
                publicUrl
                localFile {
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: DOMINANT_COLOR
                      layout: FULL_WIDTH
                    )
                  }
                }
                altText
              }
            }
          }
          ... on WpProject_Pagemodules_Modules_TwoImagesWithText {
            fieldGroupName
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTop
              paddingTopMobile
            }
            disableParallaxScroll
            backgroundColour
            content
            layout
            largeVideo
            largeImage {
              altText
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            smallImage {
              altText
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
              }
            }
            smallVideo
          }
          ... on WpProject_Pagemodules_Modules_List {
            fieldGroupName
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTop
              paddingTopMobile
            }
            backgroundColour
            heading
            list {
              hoverImage {
                publicUrl
                localFile {
                  extension
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
                altText
              }
              name
              role
              link
            }
          }
          ... on WpProject_Pagemodules_Modules_AccordionList {
            fieldGroupName
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTop
              paddingTopMobile
            }
            backgroundColour
            accordionList {
              content
              heading
            }
          }
          ... on WpProject_Pagemodules_Modules_TwoColumnText {
            fieldGroupName
            padding {
              paddingBottom
              paddingBottomMobile
              paddingTopMobile
              paddingTop
            }
            parallaxEffect
            textLeft
            textRight
            backgroundColour
          }
          ... on WpProject_Pagemodules_Modules_LinkBlock {
            fieldGroupName
            backgroundColour
            links {
              link {
                url
              }
              title
              text
              text2
            }
          }
          ... on WpProject_Pagemodules_Modules_WorkFeed {
            fieldGroupName
            backgroundColour
            heading
            text1
            text2
          }
          ... on WpProject_Pagemodules_Modules_NextPage {
            fieldGroupName
            backgroundColour
            nextPage {
              ... on WpPage {
                id
                title
                uri
                featuredImage {
                  node {
                    altText
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
              ... on WpProject {
                id
                title
                uri
                featuredImage {
                  node {
                    altText
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpProject(sort: { fields: menuOrder, order: ASC }) {
      nodes {
        id
        title
        uri
        menuOrder
        featuredImage {
          node {
            altText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        projectColour {
          projectColour
        }
      }
    }
  }
`;

